import React, { FC, ReactElement, useState } from 'react'
import { ASSETS_URL, NAVIGATION_LINKS_NORMAL } from 'constant'

import {
  StyledBackground,
  StyledButton,
  StyledIcon,
  StyledNav,
  StyledList,
  StyledLink,
  StyledLinkWrapper
} from './MobileNavigation.sc'

const MobileNavigation: FC = (): ReactElement => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleClick = () => setOpen(!isOpen)

  return (
    <div onClick={handleClick} onKeyPress={handleClick}>
      <StyledButton className="navigation__button">
        <StyledIcon isOpen={isOpen} className="navigation__icon">
          &nbsp;
        </StyledIcon>
      </StyledButton>
      <StyledBackground isOpen={isOpen}>&nbsp;</StyledBackground>
      <StyledNav isOpen={isOpen}>
        <img
          className="fastframe-logo"
          src={`${ASSETS_URL}/images/navlogo.svg`}
          alt="FastFrame"
        />
        <StyledList>
          {NAVIGATION_LINKS_NORMAL.map((link) => {
            return (
              <StyledLinkWrapper key={link} className="navigation__item">
                <StyledLink href={`#${link}`}>
                  {link.replace('-', ' ')}
                </StyledLink>
              </StyledLinkWrapper>
            )
          })}
        </StyledList>
        <img
          src={`${ASSETS_URL}/images/samsung_os_partner.svg`}
          alt="Samsung"
        />
      </StyledNav>
    </div>
  )
}

export default MobileNavigation
