import React, { FC, ReactElement, useEffect, useRef } from 'react'
import { useModal } from 'hooks'
import { AnimatedLogo, Button, VideoModal } from 'components'

import { ASSETS_URL } from 'constant'
import {
  StyledHero,
  StyledLogo,
  StyledVideoContainer,
  StyledVideo
} from './Hero.sc'

type HeroProps = {
  vimeoId: number
}

const Hero: FC<HeroProps> = ({ vimeoId }: HeroProps): ReactElement => {
  const { isShowing, toggle } = useModal()
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    setTimeout(() => videoRef?.current?.play(), 2400)
  }, [])

  return (
    <StyledHero id="casefilm">
      <StyledVideoContainer>
        <StyledLogo src={`${ASSETS_URL}/images/logo_white_row.png`} />
        <AnimatedLogo />
        <StyledVideo
          ref={videoRef}
          playsInline
          loop
          muted
          poster={`${ASSETS_URL}/images/hero_background.jpg`}
        >
          <source src={`${ASSETS_URL}/images/hero.mp4`} type="video/mp4" />
        </StyledVideo>
        <Button handleClick={() => toggle()} title="View casefilm" />
      </StyledVideoContainer>
      {isShowing && (
        <VideoModal
          handleClose={() => toggle()}
          url={`https://player.vimeo.com/video/${vimeoId}`}
        />
      )}
    </StyledHero>
  )
}

export default Hero
