import { useEffect, useRef } from 'react'

import { INIT_SECTION } from 'constant'
import { SectionName } from 'interfaces'

const usePreviousSection = (value: SectionName): SectionName => {
  const prevRef = useRef<SectionName>(INIT_SECTION)
  const activeRef = useRef<SectionName>(INIT_SECTION)

  useEffect(() => {
    if (activeRef.current !== value) {
      prevRef.current = activeRef.current
      activeRef.current = value
    }
  }, [value])
  return prevRef.current
}

export default usePreviousSection
