import React, { FC, ReactElement } from 'react'

import { StyledSectionTitle, StyledParagraph } from 'App.sc'
import { StyledBlock } from './TextBlock.sc'

interface TextProps {
  title?: string
  paragraph?: string
}

const TextBlock: FC<TextProps> = ({
  title,
  paragraph
}: TextProps): ReactElement => {
  return (
    <StyledBlock>
      {title && <StyledSectionTitle>{title}</StyledSectionTitle>}
      {paragraph && <StyledParagraph>{paragraph}</StyledParagraph>}
    </StyledBlock>
  )
}

TextBlock.defaultProps = {
  title: '',
  paragraph: ''
}

export default TextBlock
