import styled from 'styled-components'
import { StyledApp } from '../../App.sc'

export const StyledContainer = styled(StyledApp)`
  background-image: linear-gradient(
    180deg,
    #ececec 0%,
    #86b2bd 19.27%,
    #86b2bd 79.69%,
    #d8d1c1 100%
  );
  background-position: 0 0;
  background-size: 100%;
  background-repeat: repeat;
  #development .showcase {
    .item {
      &-10 {
        grid-row: 1/3;
        grid-column: 1/2;
      }
      &-17 {
        grid-row: 1/3;
        grid-column: 5/7;
      }
      @media (max-width: 768px) {
        &-3 {
          grid-row: 2/3;
          grid-column: 1/2;
        }
        &-8 {
          grid-row: 4/5;
          grid-column: 2/3;
        }
        &-10 {
          grid-row: 3/5;
          grid-column: 1/2;
        }
        &-17 {
          grid-column: 2/4;
        }
      }
    }
  }
`
