import styled from 'styled-components'

export const StyledMask = styled.div`
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
`
export const StyledWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
`
export const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: #1c1c1c;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  @media (min-width: 768px) {
    width: 75%;
    max-width: 1440px;
  }
`
export const StyledCloseButton = styled.button`
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: -2rem;
  right: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:after,
  &:before {
    content: '';
    position: absolute;
    background-color: white;
    width: 100%;
    height: 2px;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`
export const StyledIframeWrapper = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
`
export const StyledIFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
