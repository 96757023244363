import styled from 'styled-components'
import { ASSETS_URL } from 'constant'

export const StyledFooter = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  min-height: 810px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  background-image: url('${ASSETS_URL}/images/footer_black.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    padding: 2rem;
    min-height: 100vh;
    background-image: url('${ASSETS_URL}/images/footer_black_mobile.jpg');
  }
  button:not(.close-btn) {
    margin: 2rem auto;
    border-color: #fff;
    @media (max-width: 768px) {
      margin-top: auto;
    }
  }
`

export const StyledSamsungFrame = styled.img`
  max-width: 910px;
  margin: 0 auto;
  width: 100%;
  padding: 2rem 0;
  border-bottom: 1px solid #ffffff;
  @media (max-width: 768px) {
    margin-top: auto;
    padding: 1rem 0;
  }
`

export const StyledSubtitle = styled.h4`
  font-size: 2.3rem;
  font-family: 'SamsungSharpSans';
  color: #fff;
  margin: 0;
  padding: 2rem 0;
  letter-spacing: 0.12em;
  @media (max-width: 768px) {
    padding: 1rem 0;
    font-size: 1.5rem;
  }
`
