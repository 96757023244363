import { StatsOption } from 'interfaces'

export const statsDisplayOptions: StatsOption[] = [
  {
    name: 'gate_reaction',
    decimals: { time: 3, diff: 3 },
    styles: {
      time: { top: '37%', left: '36%' },
      diff: { top: '38.5%', right: '13%' }
    }
  },
  {
    name: 'power_sector_1',
    decimals: { time: 0, diff: 0 },
    styles: {
      time: { top: '47.7%', left: '14%' },
      diff: { top: '52.7%', left: '14%' }
    }
  },
  {
    name: 'power_sector_2',
    decimals: { time: 0, diff: 0 },
    styles: {
      time: { top: '56.5%', left: '14%' },
      diff: { top: '61.5%', left: '14%' }
    }
  },
  {
    name: 'power_sector_3',
    decimals: { time: 0, diff: 0 },
    styles: {
      time: { top: '65.5%', left: '14%' },
      diff: { top: '70.5%', left: '14%' }
    }
  },
  {
    name: 'bike_lift_sector_1',
    decimals: { time: 1, diff: 1 },
    styles: {
      time: { top: '47.7%', left: '55%' },
      diff: { top: '52.7%', left: '55%' }
    }
  },
  {
    name: 'bike_lift_sector_2',
    decimals: { time: 1, diff: 1 },
    styles: {
      time: { top: '56.5%', left: '55%' },
      diff: { top: '61.5%', left: '55%' }
    }
  },
  {
    name: 'bike_lift_sector_3',
    decimals: { time: 1, diff: 1 },
    styles: {
      time: { top: '65.5%', left: '55%' },
      diff: { top: '70.5%', left: '55%' }
    }
  },
  {
    name: 'downhill',
    decimals: { time: 2, diff: 3 },
    styles: {
      time: { bottom: '16.2%', left: '40%' },
      diff: { bottom: '17%', right: '13%' }
    }
  }
]
