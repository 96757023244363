import React, { FC, ReactElement } from 'react'
import CountUp from 'react-countup'

import { ASSETS_URL } from 'constant'
import { SectionName } from 'interfaces'
import { statsData, statsDisplayOptions } from 'data'

import {
  StyledWhiteNumber,
  StyledGreenNumber,
  StyledPhoneWrapper
} from './Phone.sc'

interface PhoneProps {
  activeSection: SectionName
  prevSection: SectionName
}

const Phone: FC<PhoneProps> = ({
  activeSection,
  prevSection
}: PhoneProps): ReactElement => {
  return (
    <StyledPhoneWrapper>
      <img src={`${ASSETS_URL}/images/phone.png`} alt="Phone" />
      {statsDisplayOptions.map((stat) => (
        <div key={stat.name}>
          <StyledWhiteNumber style={stat.styles.time}>
            <CountUp
              start={statsData[prevSection][stat.name].time}
              end={statsData[activeSection][stat.name].time}
              duration={1}
              decimals={stat.decimals.time}
            />
          </StyledWhiteNumber>
          <StyledGreenNumber
            style={{
              ...stat.styles.diff,
              color:
                statsData[activeSection][stat.name].diff >= 0
                  ? '#15fabe'
                  : '#ff541e'
            }}
          >
            {statsData[activeSection][stat.name].diff > 0 && '+'}
            <CountUp
              start={statsData[prevSection][stat.name].diff}
              end={statsData[activeSection][stat.name].diff}
              duration={1}
              decimals={stat.decimals.diff}
            />
          </StyledGreenNumber>
        </div>
      ))}
    </StyledPhoneWrapper>
  )
}

export default Phone
