import { StatsDataType } from 'interfaces'

export const statsData: StatsDataType = {
  init: {
    downhill: { time: 0, diff: 0 },
    gate_reaction: { time: 0, diff: 0 },
    power_sector_1: { time: 0, diff: 0 },
    power_sector_2: { time: 0, diff: 0 },
    power_sector_3: { time: 0, diff: 0 },
    bike_lift_sector_1: { time: 0, diff: 0 },
    bike_lift_sector_2: { time: 0, diff: 0 },
    bike_lift_sector_3: { time: 0, diff: 0 }
  },
  responsivness: {
    downhill: { time: 0, diff: 0 },
    gate_reaction: { time: 1.258, diff: -0.027 },
    power_sector_1: { time: 0, diff: 0 },
    power_sector_2: { time: 0, diff: 0 },
    power_sector_3: { time: 0, diff: 0 },
    bike_lift_sector_1: { time: 0, diff: 0 },
    bike_lift_sector_2: { time: 0, diff: 0 },
    bike_lift_sector_3: { time: 0, diff: 0 }
  },
  power: {
    downhill: { time: 0, diff: 0 },
    gate_reaction: { time: 1.258, diff: -0.027 },
    power_sector_1: { time: 403, diff: 20 },
    power_sector_2: { time: 284, diff: 21 },
    power_sector_3: { time: 112, diff: -25 },
    bike_lift_sector_1: { time: 0, diff: 0 },
    bike_lift_sector_2: { time: 0, diff: 0 },
    bike_lift_sector_3: { time: 0, diff: 0 }
  },
  lift: {
    downhill: { time: 2.346, diff: 0.027 },
    gate_reaction: { time: 1.258, diff: -0.027 },
    power_sector_1: { time: 403, diff: 20 },
    power_sector_2: { time: 284, diff: 21 },
    power_sector_3: { time: 112, diff: -25 },
    bike_lift_sector_1: { time: 13.5, diff: 0.3 },
    bike_lift_sector_2: { time: 23.5, diff: 0.1 },
    bike_lift_sector_3: { time: 0.0, diff: -5.3 }
  }
}
