import React, { FC } from 'react'
import {
  StyledCloseButton,
  StyledContainer,
  StyledIFrame,
  StyledIframeWrapper,
  StyledMask,
  StyledWrapper
} from './VideoModal.sc'

type VideoModalProps = {
  handleClose: () => void
  url: string
}

const VideoModal: FC<VideoModalProps> = ({
  handleClose,
  url
}: VideoModalProps) => {
  return (
    <StyledMask>
      <StyledWrapper>
        <StyledContainer>
          <StyledCloseButton
            type="button"
            onClick={handleClose}
            onKeyPress={handleClose}
            className="close-btn"
            title="Close"
          />
          <StyledIframeWrapper slot="body">
            <StyledIFrame
              src={`${url}?autoplay=1&title=0&byline=0&portrait=0`}
              title="Samsung Olympics"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </StyledIframeWrapper>
        </StyledContainer>
      </StyledWrapper>
    </StyledMask>
  )
}

export default VideoModal
