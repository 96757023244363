import React, { FC, ReactElement } from 'react'
import { ASSETS_URL } from 'constant'
import { StyledMedia } from './Media.sc'

const Media: FC = (): ReactElement => {
  const images = Array.from({ length: 34 }, (_, i) => i + 1).map(
    (index) =>
      `${ASSETS_URL}/images/media/${index.toString().padStart(2, '0')}.jpg`
  )

  return (
    <StyledMedia id="media">
      {images.map((image, index) => {
        return (
          <div key={image} className={`item item-${index + 1}`}>
            <img src={image} alt="Showcase" />
          </div>
        )
      })}
    </StyledMedia>
  )
}

export default Media
