import styled from 'styled-components'

export const StyledButton = styled.button`
  font-family: 'SamsungOne';
  align-self: flex-start;
  background-color: #fff;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  color: #000000;
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 2rem;
  padding: 1rem 3rem;
  margin: 2rem 0;
  cursor: pointer;
`
