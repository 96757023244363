import { ASSETS_URL } from 'constant'
import styled from 'styled-components'

export const StyledApp = styled.main`
  overflow: hidden;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  background-image: url('${ASSETS_URL}/images/background_bottom.png'),
    linear-gradient(
      180deg,
      #ececec 0%,
      #86b2bd 19.27%,
      #86b2bd 79.69%,
      #d8d1c1 100%
    );
  background-position: bottom right, 0 0;
  background-size: contain, 100%;
  background-repeat: no-repeat, repeat;
`

export const StyledTitle = styled.h1`
  max-width: 1200px;
  margin: 8rem auto;
  font-family: 'Teko';
  font-style: normal;
  font-weight: 300;
  font-size: 8rem;
  line-height: 9rem;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 2rem;
  @media (max-width: 768px) {
    margin: 4rem 2rem;
    font-size: 6rem;
    line-height: 6rem;
  }
`

export const StyledSectionTitle = styled.h2`
  margin: 0;
  font-family: 'Teko';
  font-style: normal;
  font-weight: 300;
  font-size: 8.85rem;
  line-height: 10rem;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  min-width: 700px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 4.85rem;
    line-height: 5rem;
    min-width: unset;
  }
`

export const StyledH3Title = styled.h3`
  font-family: 'Teko';
  font-weight: 300;
  font-size: 4.85rem;
  line-height: 5.5rem;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

export const StyledParagraph = styled.p`
  width: 670px;
  max-width: 100%;
  font-family: 'SamsungOne';
  font-size: 1.285rem;
  line-height: 1.58rem;
  margin: 2rem auto 0;
  color: #fff;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`
