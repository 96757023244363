import styled from 'styled-components'

export const StyledPhoneWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4rem;
  font-family: 'Teko';

  img {
    width: 30vw;
    max-width: 395px;
    height: auto;
    max-height: 837px;
  }

  @media (max-width: 768px) {
    left: 50%;
    top: unset;
    margin-top: -2rem;
    transform: translateX(-50%);
    width: min-content;
    img {
      position: relative;
      height: 75vh;
      width: auto;
      max-width: unset;
    }
  }
`

export const StyledWhiteNumber = styled.div`
  position: absolute;
  letter-spacing: 0.1em;
  font-size: 3.3vw;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 2.3rem;
    margin-top: -0.2rem;
  }
  @media (min-width: 1440px) {
    font-size: 3.2rem;
  }
`

export const StyledGreenNumber = styled.div`
  position: absolute;
  letter-spacing: 0.1em;
  font-size: 2.3vw;
  text-align: right;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  @media (min-width: 1440px) {
    font-size: 2.3rem;
  }
`
