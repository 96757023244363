import { Result } from 'interfaces'

export const resultsNormal: Result[] = [
  {
    title: 'CREATED TRUE VALUE',
    paragraph: 'for the athletes: a daily training tool',
    hashtag: false
  },
  {
    title: 'OLYMPIC BMX GOLD',
    paragraph: 'First Dutch ever.',
    hashtag: true
  },
  {
    title: 'UNLOCKED SECRET',
    paragraph: 'Of the perfect start',
    hashtag: true
  },
  {
    title: 'NO. 1 SPONSOR',
    paragraph: 'Named by Dutch public',
    hashtag: false
  },
  { title: '70+ MILLION', paragraph: 'Campaign reach', hashtag: false },
  {
    title: 'BAROMETER +16%',
    paragraph: 'Samsung’s Proud To Own-index',
    hashtag: false
  },
  {
    title: 'FREE PUBLICITY: 60+',
    paragraph: 'Publications + 12.9M OTS in The Netherlands',
    hashtag: false
  },
  {
    title: 'FURTHER DEVELOPMENT',
    paragraph:
      'Several road / track cycling federations are keen on implementing this innovation',
    hashtag: false
  }
]
