import styled, { css } from 'styled-components'

type IconProps = {
  isOpen: boolean
}

export const StyledButton = styled.div`
  height: 3rem;
  width: 3rem;
  position: fixed;
  top: 1rem;
  right: 1.5rem;
  z-index: 2000;
`

export const StyledIcon = styled.span<IconProps>`
  position: relative;
  height: 3px;
  margin-top: 2rem;
  background-color: #fff;
  display: inline-block;
  width: 2rem;

  &::before,
  &::after {
    display: inline-block;
    width: 2rem;
    background-color: #fff;
    content: '';
    position: absolute;
    left: 0;
    transition: all 0.2s;
  }
  &::before {
    top: -7px;
    height: 2px;
  }
  &::after {
    height: 3px;
    top: 8px;
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: transparent;
      width: 3rem;
      height: 3rem;
      &::before,
      &::after {
        top: 0;
        background-color: #000;
      }
      &::before {
        transform: rotate(135deg);
      }
      &::after {
        height: 2px;
        transform: rotate(-135deg);
      }
    `}
`

export const StyledBackground = styled.div<IconProps>`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: fixed;
  top: -2rem;
  right: -2rem;
  z-index: 1000;
  -webkit-transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  background: #fff;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scale(80);
    `}
`

export const StyledNav = styled.nav<IconProps>`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  opacity: 0;
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  -webkit-transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  img {
    padding: 2rem;
  }
  .fastframe-logo {
    width: 70%;
  }
  li {
    width: 0;
    padding: 0;
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      li {
        width: 100%;
        padding: 2rem;
      }
      opacity: 1;
      width: 100%;
    `}
`

export const StyledList = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: min-content;
  margin: 0 auto;
  padding: 0;
`

export const StyledLinkWrapper = styled.li`
  display: block;
  white-space: nowrap;
  text-align: center;
  transition: all 0.4s;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`

export const StyledLink = styled.a`
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.08em;
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.4s;
`
