import styled from 'styled-components'

export const StyledImage = styled.picture`
  width: 100%;
  overflow: hidden;
  picture,
  img {
    transition: opacity 0.2s cubic-bezier(0.05, 0.8, 0.1, 0.9),
      transform 0.3s ease-out, filter 0.2s cubic-bezier(0.05, 0.8, 0.1, 0.95);
    transform: scale(1.2);
    opacity: 0;
    filter: brightness(50%);
    width: 100%;
  }
  &.images-active {
    img {
      opacity: 1;
      transform: scale(1);
      filter: brightness(100%);
    }
  }
`
