import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { SamsungFastFrame, SamsungFastFrameApp } from 'views'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/samsungfastframe" element={<SamsungFastFrame />} />
        <Route path="/samsungfastframe-app" element={<SamsungFastFrameApp />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
