import styled from 'styled-components'

export const StyledResults = styled.div`
  display: grid;
  grid-auto-flow: column;
  padding: 0 2rem 5.2rem;
  grid-template: repeat(4, auto) / repeat(2, 1fr);
  text-align: center;
  color: #ffffff;
  letter-spacing: 0.12em;
  column-gap: 2rem;
  row-gap: 2rem;
  p {
    max-width: unset;
    width: unset;
    margin-top: 0;
  }
  @media (max-width: 768px) {
    grid-template: repeat(8, auto) / repeat(1, 1fr);
  }
`

export const StyledHashtag = styled.span`
  font-family: 'SamsungSharpSans';
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2rem;
`
