import React, { FC, ReactElement } from 'react'
import { Result } from 'interfaces'

import { StyledParagraph, StyledH3Title } from 'App.sc'
import { StyledHashtag, StyledResults } from './Results.sc'

type ResultsProps = {
  results: Result[]
}

const Results: FC<ResultsProps> = ({ results }: ResultsProps): ReactElement => {
  return (
    <StyledResults>
      {results.map((result) => (
        <div key={result.title}>
          <StyledH3Title>{result.title}</StyledH3Title>
          <StyledParagraph>
            {result.paragraph}
            {result.hashtag && <StyledHashtag> #DoWhatYouCant</StyledHashtag>}
          </StyledParagraph>
        </div>
      ))}
    </StyledResults>
  )
}

export default Results
