import { useLayoutEffect, useState } from 'react'
import { Sizes } from 'interfaces'

const useWindowSize = () => {
  const [size, setSize] = useState<Sizes>({ width: 0, windowHeight: 0 })
  const [isMobile, setMobile] = useState<boolean>(false)

  useLayoutEffect(() => {
    function updateSize() {
      setSize({ width: window.innerWidth, windowHeight: window.innerHeight })
      setMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', updateSize)
    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return { size, isMobile }
}

export default useWindowSize
