export const ASSETS_URL =
  'https://sebn.ams3.cdn.digitaloceanspaces.com/ideasthatmove/assets'

export const IMAGE_RATIO = 0.5625
export const FRAME_COUNT = 108
export const INIT_SECTION = 'init'
export const CANVA_WIDTH = 1240
export const CANVA_HEIGHT = 698

export const NAVIGATION_LINKS_NORMAL = [
  'casefilm',
  'background',
  'brand-strategy',
  'idea',
  'innovation',
  'results'
]
