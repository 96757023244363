import styled from 'styled-components'

export const StyledNavigation = styled.nav`
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 999;
  background: #ffffff80;
  white-space: nowrap;
  -ms-overflow-style: none;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  padding: 1rem 2rem;

  ul {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
  }
`

export const StyledLinkItem = styled.li`
  position: relative;
  display: inline-block;
  margin: 0 2rem;
  &:last-child {
    margin: 0 0 0 2rem;

    &.active-link {
      &:after {
        width: calc(100% -1rem);
      }
    }
    a {
      padding-right: 0;
    }
  }
  &.active-link {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      left: 0;
      background-color: #000;
      will-change: width, left;
      animation: growing 0.4s linear;
    }
  }

  @keyframes growing {
    0% {
      width: 1px;
      left: 50%;
      transform: translateX(-50%);
    }
    100% {
      width: 100%;
      left: 0;
      transform: translateX(0);
    }
  }
`

export const StyledLink = styled.a`
  color: #000;
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  font-family: 'SamsungOne';
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.08em;
`

export const StyledImageContainer = styled.div`
  width: 100%;
  padding-right: 2rem;
  height: 2rem;
`
