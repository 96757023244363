import React, { FC, ReactElement } from 'react'
import {
  ImageBlock,
  DesktopNavigation,
  TextBlock,
  Showcase,
  MobileNavigation,
  Footer
} from 'components'
import { Development, FastFrame, Hero, Results } from 'containers'
import { useWindowSize } from 'hooks'
import { ASSETS_URL } from 'constant'
import { resultsApp } from 'data'
import { StyledTitle } from 'App.sc'
import { StyledContainer } from './SamsungFastFrameApp.sc'

const SamsungFastFrame: FC = (): ReactElement => {
  const { isMobile } = useWindowSize()
  return (
    <StyledContainer>
      {isMobile ? <MobileNavigation /> : <DesktopNavigation />}
      <section id="casefilm">
        <Hero vimeoId={652860953} />
        <StyledTitle>Unlocking the secret of the perfect start</StyledTitle>
      </section>
      <section id="background">
        <ImageBlock
          imageSrcMobile={`${ASSETS_URL}/images/image_1_mobile.jpg`}
          imageSrcDesktop={`${ASSETS_URL}/images/image_1.jpg`}
          imageAlt="Judy"
        />
        <TextBlock
          title="Background"
          paragraph="Samsung is worldwide partner of the Olympic Games since 1998. For the Summer Games in Tokyo, Samsung Netherlands sponsored BMX riders Niek Kimmann and Judy Baauw. The brief was to develop a strategy and creative idea to claim sponsorship of the two Dutch Olympic athletes."
        />
      </section>
      <section id="brand-strategy">
        <ImageBlock
          imageSrcMobile={`${ASSETS_URL}/images/image_2_mobile.jpg`}
          imageSrcDesktop={`${ASSETS_URL}/images/image_2.jpg`}
          imageAlt="#DoWhatYouCant"
        />
        <TextBlock
          title="BRAND STRATEGY"
          paragraph="Samsung stands for: ‘Meaningful progress comes from daring to defy barriers.’ With the tagline: Do what you can’t. So, when Samsung wants to sponsor Olympic athletes, it is not enough to make a commercial about it. Practice what you preach. Find a meaningful solution/innovation that makes the brand promise concrete and tangible, in order to earn awareness. As a real contributing tech sponsor."
        />
      </section>
      <section id="idea">
        <ImageBlock
          imageSrcMobile={`${ASSETS_URL}/images/image_3_mobile.jpg`}
          imageSrcDesktop={`${ASSETS_URL}/images/image_3.jpg`}
          imageAlt="Idea"
        />
        <TextBlock
          title="Idea"
          paragraph="As sponsor of BMX athletes, you can HOPE for Olympic success. But as tech brand saying ‘do what you can’t’, why don’t you HELP them to be successful? BMX is all about the perfect start, where 70% of the races are won. This start consists of 3 elements: reaction speed, power and the angle at which the bike is pulled up. The ideal combination of these 3 factors is the biggest mystery in BMX. Until we developed the Samsung FastFrame."
        />
      </section>
      <section id="innovation">
        <FastFrame />
        <TextBlock
          title="INNOVATION"
          paragraph="The Samsung FastFrame is a customised BMX innovation with built-in technology and app that measures the 3 key factors of the start in real time. For the first time in BMX history. The significant data, directly transmitted to the coaches’ smartphones, unlocked this ideal combination of the perfect start."
        />
      </section>
      <section id="technology">
        <Showcase
          gridArea={{
            desktop: 'repeat(2, 1fr) / repeat(6, 1fr)',
            mobile: 'repeat(4, 1fr) / repeat(3, 1fr)'
          }}
          imageIndexes={
            isMobile
              ? [2, 4, 5, 11, 12, 13, 14, 15, 16]
              : Array.from({ length: 9 }, (_, i) => i + 1)
          }
        />
        <TextBlock
          title="TECHNOLOGY"
          paragraph="The full solution is a mix of an Android app running on coaches' and athletes' smartphone for displaying the results and managing the entire platform, a Windows 10 server application that takes care of managing the heavy lifting of data storage and analysis and the Android app running on the hub in the bike frame responsible for communicating with all the different sensors and relaying this information to the server."
        />
      </section>
      <section id="development">
        <Showcase
          gridArea={{
            desktop: 'repeat(2, 1fr) / repeat(6, 1fr)',
            mobile: 'repeat(4, 1fr) / repeat(3, 1fr)'
          }}
          imageIndexes={
            isMobile
              ? [1, 3, 10, 17, 6, 8, 7, 9]
              : [10, 11, 12, 13, 14, 15, 16, 17]
          }
        />
        <Development />
      </section>

      <section id="results">
        <TextBlock
          title="RESULTS"
          paragraph="After the press release, in no time the whole of The Netherlands knew about this ground-breaking innovation. And about Samsung being a real contributing tech sponsor."
        />
        <Results results={resultsApp} />
      </section>
      <Footer />
    </StyledContainer>
  )
}

export default SamsungFastFrame
