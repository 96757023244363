import React, { FC, useState, useEffect, useRef, ReactElement } from 'react'
import Scrollspy from 'react-scrollspy'
import { ASSETS_URL, NAVIGATION_LINKS_NORMAL } from 'constant'

import {
  StyledImageContainer,
  StyledLink,
  StyledLinkItem,
  StyledNavigation
} from './Navigation.sc'

const Navigation: FC = (): ReactElement => {
  const [activeSection, setActive] = useState<string>('introductie')
  const refContainer = useRef<HTMLElement>(null)
  const handleChange = (el: HTMLElement) => {
    if (!el?.id) return
    setActive(el.id)
  }

  useEffect(() => {
    const activeLink = document.querySelector(`#${activeSection}-link`)
    if (activeLink) {
      const { left } = activeLink.getBoundingClientRect()
      refContainer?.current?.scrollTo(left, 0)
    }
  }, [activeSection])

  return (
    <StyledNavigation ref={refContainer}>
      <StyledImageContainer>
        <img src={`${ASSETS_URL}/images/navlogo.svg`} alt="FastFrame" />
      </StyledImageContainer>
      <Scrollspy
        offset={-200}
        currentClassName="active-link"
        onUpdate={(el) => handleChange(el)}
        items={NAVIGATION_LINKS_NORMAL}
      >
        {NAVIGATION_LINKS_NORMAL &&
          NAVIGATION_LINKS_NORMAL.map((link) => {
            return (
              <StyledLinkItem
                className={`${link === activeSection ? 'active-link' : ''}`}
                id={`${link}-link`}
                key={link}
              >
                <StyledLink onClick={() => setActive(link)} href={`#${link}`}>
                  {link.replace('-', ' ')}
                </StyledLink>
              </StyledLinkItem>
            )
          })}
      </Scrollspy>
    </StyledNavigation>
  )
}

export default Navigation
