import styled from 'styled-components'
import { StyledResults } from 'containers/Results/Results.sc'

export const StyledDevelopment = styled.div`
  padding: 5.2rem 2rem;

  h2 {
    text-align: center;
  }
`

export const StyledDevelopmentGrid = styled(StyledResults)`
  grid-template: repeat(3, auto) / repeat(2, 1fr);
  grid-auto-flow: row;
  padding: 2rem 2rem 0 2rem;

  .text-3 {
    grid-row: 2/4;
  }
  @media (max-width: 768px) {
    grid-template: repeat(5, auto) / repeat(1, 1fr);
    .text-3 {
      grid-row: auto;
    }
  }
`
