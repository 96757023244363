import React, { FC, ReactElement } from 'react'

import { StyledParagraph, StyledH3Title, StyledSectionTitle } from 'App.sc'
import { StyledDevelopment, StyledDevelopmentGrid } from './Development.sc'

const Development: FC = (): ReactElement => {
  const statements = [
    'Creating a functional design for all elements, software, firmware and hardware to create a solution that analyses the results in real-time.',
    'Developing the code architecture to talk to the torque, inertial measurement unit sensors measuring the performance and angular data from the bike.',
    'Synchronising all these different data streams in to an atomic clock accurate single data stream, based on the BMX track timing system as context. All data was in context to a master-clock so the start-hill and bike could be mapped in relation to each other in angle and position. Without this context, the bike angle would always be related to the earths ground surface and not the changing angle of the start-hill. Similarly, for the torque output this could be mapped to each individual discrete section of the start-hill where the transitions happen of the angle changes.',
    '3D design and print case and mount to integrateand house all electronics inside the BMX bike.',
    'Tech tests for timing systems and data capture on the BMX-track.'
  ]
  return (
    <StyledDevelopment>
      <StyledSectionTitle>Development</StyledSectionTitle>
      <StyledDevelopmentGrid>
        {statements.map((text, index) => (
          <div className={`text-${index + 1}`} key={text}>
            <StyledH3Title>{index + 1}</StyledH3Title>
            <StyledParagraph>{text}</StyledParagraph>
          </div>
        ))}
      </StyledDevelopmentGrid>
    </StyledDevelopment>
  )
}

export default Development
