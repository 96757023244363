import styled from 'styled-components'

type StyledLogoProps = {
  animate: boolean
}

export const StyledLogo = styled.svg<StyledLogoProps>`
  overflow: visible;
  width: 100%;
  max-width: 800px;
  align-self: center;
  justify-self: center;
  margin-top: 4rem;

  @media (max-width: 768px) {
    margin-top: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  .top {
    animation: ${({ animate }: StyledLogoProps) =>
      animate ? 'rightglitch 1.5s ease-in-out .8s' : 'none'};
  }

  @keyframes rightglitch {
    0% {
      transform: translateX(-20px);
    }
    90% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(0px);
    }
  }
`
