import React, { FC, ReactElement } from 'react'
import { useModal } from 'hooks'
import { Button, VideoModal } from 'components'

import { ASSETS_URL } from 'constant'
import { StyledFooter, StyledSamsungFrame, StyledSubtitle } from './Footer.sc'

const Footer: FC = (): ReactElement => {
  const { isShowing, toggle } = useModal()

  return (
    <StyledFooter>
      <StyledSamsungFrame src={`${ASSETS_URL}/images/hero_logo.svg`} />
      <StyledSubtitle>THE MAKING OF</StyledSubtitle>
      <Button handleClick={() => toggle()} title="VIEW THE MAKING OF" />
      {isShowing && (
        <VideoModal
          handleClose={() => toggle()}
          url="https://player.vimeo.com/video/656940626"
        />
      )}
    </StyledFooter>
  )
}

export default Footer
