import styled from 'styled-components'

export const StyledBlock = styled.div`
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5.2rem 2rem;
  @media (max-width: 768px) {
    padding: 4rem 2rem;
    width: 100%;
  }
`
