import { Result } from 'interfaces'

export const resultsApp: Result[] = [
  {
    title: 'CREATED TRUE VALUE',
    paragraph: 'for the athletes: a daily training tool',
    hashtag: false
  },
  {
    title: 'OLYMPIC BMX GOLD',
    paragraph: 'First Dutch ever.',
    hashtag: true
  },
  {
    title: '30 MILLISECONDS',
    paragraph: 'Advantage at the start',
    hashtag: false
  },
  {
    title: 'NO. 1 SPONSOR',
    paragraph: 'Named by Dutch public',
    hashtag: false
  },
  {
    title: 'FREE PUBLICITY: 60+',
    paragraph: 'Publications + 12.9M OTS in The Netherlands',
    hashtag: false
  },
  {
    title: 'BAROMETER +16%',
    paragraph: 'Samsung’s Proud To Own-index',
    hashtag: false
  },
  {
    title: 'CONTINUATION TO PARIS 2024',
    paragraph: 'All national BMX athletes',
    hashtag: false
  },
  {
    title: 'FURTHER DEVELOPMENT',
    paragraph:
      'Several road / track cycling federations are keen on implementing this innovation',
    hashtag: false
  }
]
