import styled from 'styled-components'

export const StyledMedia = styled.section`
  display: grid;
  grid-template: repeat(6, 2fr) / repeat(6, 1fr);
  margin-bottom: 8rem;

  .item {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transition: filter 0.3s, transform 0.2s;
      filter: brightness(90%);

      &:hover {
        transform: scale(1.2);
        filter: brightness(100%);
      }
    }
    &-17 {
      grid-row: 3 / 5;
      grid-column: 5 / 6;
    }
    &-23 {
      display: none;
    }
    &-27 {
      grid-row: 5 / 7;
      grid-column: 3 / 4;
    }
    &-30 {
      grid-row: 5 / 7;
      grid-column: 6 / 7;
    }
  }
  @media (max-width: 768px) {
    grid-template: repeat(12, 2fr) / repeat(3, 1fr);
    .item {
      &-17 {
        grid-row: 9 / 11;
        grid-column: 2 / 3;
      }
      &-27 {
        grid-column: 3 / 4;
      }
      &-30 {
        grid-row: 11 / 13;
        grid-column: 3 / 4;
      }
    }
  }
`
