import { GridArea } from 'interfaces'
import styled from 'styled-components'

type StyledShowecaseProps = {
  gridArea?: GridArea
}

export const StyledShowcase = styled.section<StyledShowecaseProps>`
  display: grid;
  grid-template: ${({ gridArea }) => gridArea?.desktop};
  width: 100%;
  overflow: hidden;
  background: #000;
  .item {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transition: filter 0.3s, transform 0.2s;
      filter: brightness(90%);

      &:hover {
        transform: scale(1.2);
        filter: brightness(100%);
      }
    }
    &-3 {
      grid-row: 2 / 3;
    }
    &-4 {
      grid-row: 2 / 3;
    }
    &-5 {
      grid-row: 1 / 3;
      grid-column: 3 / 5;
    }
    &-10 {
      grid-row: 3 / 5;
    }
    &-17 {
      grid-row: 3 / 5;
      grid-column: 5 / 7;
    }
  }
  @media (max-width: 768px) {
    grid-template: ${({ gridArea }) => gridArea?.mobile || gridArea?.desktop};
    .item {
      &-2 {
        grid-row: 1 / 2;
      }
      &-3 {
        grid-row: 6 / 7;
      }
      &-5 {
        grid-row: 1 / 3;
        grid-column: 2 / 4;
      }
      &-10 {
        grid-row: 7 / 9;
      }
      &-11,
      &-12,
      &-13 {
        grid-row: 3 / 4;
      }
      &-14,
      &-15,
      &-16 {
        grid-row: 4 / 5;
      }
      &-17 {
        grid-row: 5 / 7;
        grid-column: 2 / 4;
      }
    }
  }
`
