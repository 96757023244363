import React, { FC, ReactElement } from 'react'
import { StyledButton } from './Button.sc'

interface ButtonProps {
  title: string
  handleClick?: () => void
}

const Button: FC<ButtonProps> = ({
  title,
  handleClick
}: ButtonProps): ReactElement => {
  return <StyledButton onClick={handleClick}>{title}</StyledButton>
}

Button.defaultProps = {
  handleClick: () => {}
}

export default Button
