import React, { FC, ReactElement } from 'react'
import { ASSETS_URL } from 'constant'
import { ShowcaseProps } from 'interfaces'

import { StyledShowcase } from './Showcase.sc'

const Showcase: FC<ShowcaseProps> = ({
  imageIndexes,
  gridArea
}: ShowcaseProps): ReactElement => {
  const images = imageIndexes.map((index) => ({
    src: `${ASSETS_URL}/images/showcase/${index
      .toString()
      .padStart(2, '0')}.png`,
    index
  }))

  return (
    <StyledShowcase className="showcase" gridArea={gridArea}>
      {images.map(({ src, index }) => {
        return (
          <div key={index} className={`item item-${index}`}>
            <img src={src} alt="Showcase" />
          </div>
        )
      })}
    </StyledShowcase>
  )
}

Showcase.defaultProps = {
  gridArea: {
    desktop: 'repeat(4, 1fr) / repeat(5, 1fr)',
    mobile: 'repeat(8, 1fr) / repeat(3, 1fr)'
  }
}

export default Showcase
