import React, { FC, ReactElement } from 'react'
import { useInView } from 'react-intersection-observer'

import { StyledImage } from './ImageBlock.sc'

interface ImageBlockProps {
  imageSrcMobile: string
  imageSrcDesktop: string
  imageAlt: string
}

const ImageBlock: FC<ImageBlockProps> = ({
  imageSrcMobile,
  imageSrcDesktop,
  imageAlt
}: ImageBlockProps): ReactElement => {
  const { ref, inView } = useInView()

  return (
    <StyledImage className={inView ? 'images-active' : 'images'}>
      <source srcSet={imageSrcDesktop} media="(min-width:769px)" />
      <source srcSet={imageSrcMobile} media="(min-width:240px)" />
      <img ref={ref} src={imageSrcDesktop} alt={imageAlt} />
    </StyledImage>
  )
}

export default ImageBlock
