import styled from 'styled-components'

export const StyledHero = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  min-height: 810px;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .animatedLogo {
    width: 100%;
    max-width: 800px;
    align-self: center;
    justify-self: center;
    margin-top: 4rem;
    z-index: 2;
    @media (max-width: 768px) {
      margin-top: auto;
    }
  }
  @media (max-width: 768px) {
    align-items: center;
    min-height: 100vh;
  }
  button:not(.close-btn) {
    margin-top: auto;
    margin-bottom: 4rem;
    z-index: 2;
    align-self: center;
    border-color: #fff;
    @media (max-width: 768px) {
      margin-bottom: auto;
      margin-top: 0;
    }
  }
`
export const StyledLogo = styled.img`
  margin: 6rem 4rem 4rem auto;
  z-index: 2;
  @media (max-width: 768px) {
    width: 60%;
    order: 5;
    margin: 0 auto;
  }
`

export const StyledVideoContainer = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  padding: 2rem;
`

export const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
`
