import styled from 'styled-components'

type StyledWrapperProps = {
  height: number
}

type StyledCanvasProps = {
  isFixed: boolean
  position: number
}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  position: relative;
  width: 100%;
  height: ${({ height }: StyledWrapperProps) => height}px;
  @media (max-width: 768px) {
    margin-bottom: 8rem;
  }
`

export const StyledContentWrapper = styled.div<StyledCanvasProps>`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: ${({ isFixed }: StyledCanvasProps) =>
    isFixed ? 'fixed' : 'absolute'};
  top: ${({ position }: StyledCanvasProps) => position}px;
  right: 50%;
  transform: translateX(50%);
  @media (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export const StyledContent = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: auto;
  background: #000;
  position: relative;
  canvas {
    width: 70vw;
  }

  @media (max-width: 768px) {
    margin: 0;
    height: calc(100vh);
    padding-top: 3rem;
    canvas {
      width: 100vw;
    }
  }
`
