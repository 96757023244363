import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  FC,
  ReactElement
} from 'react'
import { useInView } from 'react-intersection-observer'

import { Phone } from 'components'
import { useWindowSize, usePrevious } from 'hooks'
import { ASSETS_URL, CANVA_HEIGHT, CANVA_WIDTH, FRAME_COUNT } from 'constant'
import { SectionName } from 'interfaces'
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledContent
} from './FastFrame.sc'

declare global {
  interface Window {
    usePreloadImagesData?: Record<string, unknown[]>
  }
}

const FastFrame: FC = (): ReactElement => {
  const {
    size: { windowHeight }
  } = useWindowSize()
  const [isFixed, setFixed] = useState<boolean>(false)
  const [position, setPosition] = useState<number>(0)
  const [imageIndex, setImageIndex] = useState<number>(1)
  const [activeSection, setActiveSection] = useState<SectionName>('init')
  const prevSection: SectionName = usePrevious(activeSection)
  const { ref, inView } = useInView()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const getCurrentFrameUrl = useCallback(
    (index: number) =>
      `${ASSETS_URL}/images/bike/${index.toString().padStart(3, '0')}.jpg`,
    []
  )

  const handleScroll = useCallback(() => {
    const wrapper = wrapperRef.current
    if (!wrapper) return
    const { y, height, top } = wrapper.getBoundingClientRect()

    if (y > 0) {
      setFixed(false)
      return
    }
    const scrollFraction = (top * -1) / (height - window.innerHeight)

    const frameIndex = Math.min(
      FRAME_COUNT - 1,
      Math.ceil(scrollFraction * FRAME_COUNT)
    )

    if (y < 0 && y - windowHeight + height > 0) {
      setFixed(true)
      setPosition(0)
    } else {
      setFixed(false)
    }

    if (y - windowHeight + height < 0) {
      setPosition(height - windowHeight)
    }

    switch (true) {
      case frameIndex >= 24 && frameIndex < 48:
        setActiveSection('responsivness')
        break
      case frameIndex >= 49 && frameIndex < 80:
        setActiveSection('power')
        break
      case frameIndex >= 80:
        setActiveSection('lift')
        break
      default:
        setActiveSection('init')
        break
    }

    requestAnimationFrame(() => setImageIndex(frameIndex + 1))
  }, [windowHeight])

  useEffect(() => {
    // Preload images
    const imageSrcs = Array.from({ length: FRAME_COUNT }, (_, i) => i + 1).map(
      getCurrentFrameUrl
    )
    window.usePreloadImagesData = window.usePreloadImagesData ?? {}
    window.usePreloadImagesData.data = []

    imageSrcs.forEach((src) => {
      const img = new Image()
      img.src = src
      // keep a reference to the image
      window.usePreloadImagesData?.data.push(img)
    })

    return () => {
      delete window.usePreloadImagesData
    }
  }, [getCurrentFrameUrl])

  useEffect(() => {
    if (inView) {
      window.addEventListener('scroll', handleScroll)
    } else {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [inView, handleScroll])

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d')
    const img = new Image()
    img.src = getCurrentFrameUrl(imageIndex)
    img.onload = () => {
      context?.drawImage(img, 0, 0, CANVA_WIDTH, CANVA_HEIGHT)
    }
  }, [imageIndex, getCurrentFrameUrl])

  return (
    <div ref={ref}>
      <StyledWrapper height={windowHeight * 6} ref={wrapperRef}>
        <StyledContentWrapper position={position} isFixed={isFixed}>
          <StyledContent>
            <canvas ref={canvasRef} width={CANVA_WIDTH} height={CANVA_HEIGHT} />
            <Phone activeSection={activeSection} prevSection={prevSection} />
          </StyledContent>
        </StyledContentWrapper>
      </StyledWrapper>
    </div>
  )
}

export default FastFrame
